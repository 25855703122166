define(["./monitoringModule", "json!./mbr.json"], function (monitoringModule, mbr) {

    "use strict";
    monitoringModule.service("mbrService", ["$http", "uploadService", "downloadBinary", "$translate", "$filter", "remoteExceptionHandler", function ($http, uploadService, downloadBinary, $translate, $filter, remoteExceptionHandler) {

        return {
            getOccurrenceListeners: getOccurrenceListeners,
            updateOccurrenceListenersParam: updateOccurrenceListenersParam,
            transitionChangeStatus: transitionChangeStatus,
            createOccurrence: createOccurrence,
            addOccurrenceComment: addOccurrenceComment,
            addTransitionComment: addTransitionComment,
            updateCurrentDriver: updateCurrentDriver,
            importTransitionSpreadsheet: importTransitionSpreadsheet,
            downloadAllMonitorablesAttachments: downloadAllMonitorablesAttachments,

            //after monitorable refactoring routes
            finishMonitorables: finishMonitorables,
            cancelMonitorables: cancelMonitorables,

            //trackingMobileRoutes
            updateTrackingInfo: updateTrackingInfo,
            updateTrackingInfoAndStart: updateTrackingInfoAndStart,
            //trackingMonitorable
            createOrUpdateTrackingMonitorableList: createOrUpdateTrackingMonitorableList,

            //republicação de monitoraveis e transição
            publishOccurrence: publishOccurrence,
            publishTransition: publishTransition,

            saveOccurrenceStatus: saveOccurrenceStatus,
            deleteOccurrenceStatus: deleteOccurrenceStatus,

            //sensorConfiguration creation routes
            createSpecificConfigurationForMonitorable: createSpecificConfigurationForMonitorable,
            createSensorConfiguration: createSensorConfiguration
        };

        // Utilizar os métodos abaixo para evitar problemas de '//' no path.
        function httpGet(path, config) {
            return $http.get(sanitize(path), config || {});
        }

        function httpPost(path, payload, config) {
            return $http.post(sanitize(path), payload, config || {});
        }

        function httpDelete(path, config) {
            return $http.get(sanitize(path), config || {});
        }

        function httpCall(method, path, data, headers) {
            return $http({
                method: method,
                url: sanitize(path),
                data: data,
                headers: headers
            });
        }


        function importTransitionSpreadsheet(file) {
            return uploadService.post(sanitize(mbr.baseUrl + "/transition/import/transitions/"), file)
                .then(getData);
        }

        function finishMonitorables(monitorableIds) {
            return httpPost(getServiceUrl("monitorable") + "finishMonitorablesById", monitorableIds, {
                params: {
                    completionSource: "FrontEnd"
                }
            }).then(getData);
        }

        function cancelMonitorables(monitorableIds) {
            return httpPost(getServiceUrl("monitorable") + "cancelMonitorablesByIds", monitorableIds, {
                params: {
                    completionSource: "FrontEnd"
                }
            }).then(getData);
        }

        s

        function createOrUpdateTrackingMonitorableList(trackingMonitorable) {
            return httpPost(getServiceUrl("trackCreateOrUpdateList"), trackingMonitorable).then(getData);
        }

        function updateTrackingInfo(devicesByMonitorable) {
            return httpPost(getServiceUrl("track"), devicesByMonitorable).then(getData);
        }

        function updateTrackingInfoAndStart(devicesByMonitorable) {
            return httpPost(getServiceUrl("trackStart"), devicesByMonitorable).then(getData);
        }

        function getServiceUrl(service) {
            return mbr.baseUrl + mbr.services[service];
        }

        function getOccurrenceListeners() {
            return httpGet(getServiceUrl("occurrenceListener"));
        }

        function updateOccurrenceListenersParam(name, param) {
            return httpPost(getServiceUrl("occurrenceListener") + "/" + name, param).then(getData);
        }

        function updateCurrentDriver(monitorableId, driverId) {
            return httpPost(getServiceUrl("monitorable") + monitorableId + "/current-driver", driverId).then(getData);
        }

        function createOccurrence(occurrenceAndComment, files) {
            var data = new FormData();
            data.append("occurrence", new Blob([JSON.stringify(occurrenceAndComment)], {
                type: "application/json"
            }));
            if (files) {
                for (var index in files) {
                    data.append("attachments", files[index]);
                }
            }
            return httpCall("POST", getServiceUrl("occurrence") + "/create", data, {
                "Content-Type": undefined
            }).then(getData);
        }

        function transitionChangeStatus(data) {
            return httpCall("POST", getServiceUrl("transition") + "/finalize-comment", data, {
                "Content-Type": undefined
            }).then(getData);
        }

        function addTransitionComment(transitionId, content, fileList) {
            return addComment(null, transitionId, content, fileList, "transition");
        }

        function addOccurrenceComment(occurrenceId, content, newStatus, fileList) {
            return addComment(newStatus, occurrenceId, content, fileList, "occurrence");
        }

        function addComment(newStatus, entityId, content, fileList, serviceId) {
            if (!newStatus) {
                newStatus = {};
            }
            var data = new FormData();
            data.append("comment", new Blob([JSON.stringify({
                content: content
            })], {
                type: "application/json"
            }));
            data.append("status", new Blob([JSON.stringify(newStatus)], {
                type: "application/json"
            }));
            if (fileList) {
                for (var index in fileList) {
                    data.append("attachments", fileList[index]);
                }
            }
            return httpCall("POST", getServiceUrl(serviceId) + entityId + "/comment", data, {
                "Content-Type": undefined
            }).then(getData);
        }


        function getData(response) {
            return response.data;
        }

        function publishOccurrence(occurrenceId) {
            return httpGet(getServiceUrl("occurrence") + "publish-occurrence" + "/" + occurrenceId);
        }

        function publishTransition(transitionId) {
            return httpGet(getServiceUrl("transition") + "publish-transition" + "/" + transitionId);
        }

        function deleteOccurrenceStatus(id) {
            return httpDelete(getServiceUrl("occurrence") + "/status/delete/" + id).then(getData);
        }

        function saveOccurrenceStatus(occurrenceStatus) {
            return httpPost(getServiceUrl("occurrence") + "/status/save", occurrenceStatus).then(getData);
        }

        function createSpecificConfigurationForMonitorable(id, configs) {
            return httpPost(getServiceUrl("sensorConfiguration") + "/create/specific/" + id, configs).then(getData);
        }

        function createSensorConfiguration(sensors) {
            return httpPost(getServiceUrl("sensorConfiguration") + "/createOrDelete", sensors).then(getData);
        }

        function downloadAllMonitorablesAttachments(monitorableIds) {
            var dateFilter = $filter("date");
            return downloadBinary.post(getServiceUrl("monitorable") + "downloadAllMonitorablesAttachments",
                monitorableIds, $translate.instant("monitoring.downloadAllMonitorableAttachments") + "-" + dateFilter(new Date(), "yyyy-MM-dd") + ".zip")
                .catch(remoteExceptionHandler());
        }

        /**
         * Trata barras duplas
         * */
        function sanitize(path) {
            return path
                .replace("//", "/");
        }
    }]);
});
