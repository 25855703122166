define(["angular", "./trackingModule", "json!./tracking.json"], function (angular, trackingModule, trackingServer) {
    "use strict";

    trackingModule.service("trackingService", ["$http", function ($http) {

        return {
            findByMonitorableId: findByMonitorableId,
            findNotFinishedByMonitorableId: findNotFinishedByMonitorableId,
            findLastSignal: findLastSignal,
            listProviders: listProviders,
            findDevice: findDevice,
            findByDescriptionLike: findByDescriptionLike,
            findByDescriptionLikeWithoutMonitorable: findByDescriptionLikeWithoutMonitorable,
            findByDeviceIdLike: findByDeviceIdLike,
            findByDeviceIdLikeWithoutMonitorable: findByDeviceIdLikeWithoutMonitorable,
            truckDeviceFilter: truckDeviceFilter,
            saveOrUpdateTruckDevice: saveOrUpdateTruckDevice,
            deleteTruckDevice: deleteTruckDevice,
            getLastSignalsForDeviceSince: getLastSignalsForDeviceSince,
            findTruckDeviceDeviceIdAndProviderId: truckDeviceDeviceIdAndProviderId,
            getAllSignals: getAllSignals,
            findDeviceByMonitorableId: findDeviceByMonitorableId,
            getSignalsByMonitorableAndDevice: getSignalsByMonitorableAndDevice,
            getSignalsByMonitorableAndDevicePageable: getSignalsByMonitorableAndDevicePageable,
            getLatestOrphanSignals: getLatestOrphanSignals,
            getSignalToleranceInterval: getSignalToleranceInterval,
        };

        function findByMonitorableId(monitoringTripId) {
            return $http.get(getServiceUrl("monitorable") + "/" + monitoringTripId).then(getData);
        }

        function findNotFinishedByMonitorableId(monitoringTripId) {
            return $http.get(getServiceUrl("notFinishedMonitorable") + "/" + monitoringTripId).then(getData);
        }

        function findLastSignal(trackingMonitorableId) {
            return $http.get(getServiceUrl("monitorableSignal"), {
                params: {
                    trackingMonitorableId: trackingMonitorableId
                }
            }).then(getData);
        }

        function getData(response) {
            return response.data;
        }

        function listProviders() {
            return $http.get(getServiceUrl("listProviders")).then(function (response) {
                return response.data;
            });
        }

        function findDevice(deviceId) {
            return $http.get(getServiceUrl("truckDevice") + "/" + deviceId).then(function (response) {
                return response.data;
            });
        }

        function findDeviceByMonitorableId(monitorableId) {
            return $http.get(getServiceUrl("truckDeviceByMonitorable") + "/" + monitorableId).then(function (response) {
                return response.data;
            });
        }

        function findByDescriptionLike(description, providerIds) {
            return $http({
                url: getServiceUrl("truckDevice") + "/findByDescriptionLike",
                method: "POST",
                params: {
                    value: description,
                    providerIds: providerIds
                }
            }).then(getData);
        }
        function findByDescriptionLikeWithoutMonitorable(description, providerIds) {
            return $http({
                url: getServiceUrl("truckDevice") + "/findByDescriptionLikeWithoutMonitorable",
                method: "POST",
                params: {
                    value: description,
                    providerIds: providerIds
                }
            }).then(getData);
        }

        function findByDeviceIdLike(deviceId, providerIds) {
            return $http({
                url: getServiceUrl("truckDevice") + "/findByDeviceIdLike",
                method: "POST",
                params: {
                    value: deviceId,
                    providerIds: providerIds
                }
            }).then(getData);
        }
        function findByDeviceIdLikeWithoutMonitorable(deviceId, providerIds) {
            return $http({
                url: getServiceUrl("truckDevice") + "/findByDeviceIdLikeWithoutMonitorable",
                method: "POST",
                params: {
                    value: deviceId,
                    providerIds: providerIds
                }
            }).then(getData);
        }

        function getServiceUrl(service) {
            return trackingServer.baseUrl + trackingServer.services[service];
        }

        function truckDeviceFilter(template, firstResult, maxResults) {
            var filterUrl = getServiceUrl("truckDevice") + "/filter";
            return $http.post(filterUrl, template, {
                params: {
                    firstResult: firstResult,
                    maxResults: maxResults
                }
            }).then(getData);
        }

        function getLastSignalsForDeviceSince(deviceId, timestamp) {
            return $http({
                url: getServiceUrl("signalTruckDevice"),
                method: "GET",
                params: {
                    id: deviceId,
                    time: timestamp
                }
            }).then(getData);
        }

        function truckDeviceDeviceIdAndProviderId(deviceId, providerId) {
            return $http({
                url: getServiceUrl("truckDeviceByDeviceIdAndProviderId"),
                method: "GET",
                params: {
                    deviceId: deviceId,
                    providerId: providerId
                }
            }).then(getData);
        }

        function saveOrUpdateTruckDevice(truckDevice) {
            if (truckDevice.id) {
                return $http.post(getServiceUrl("truckDevice") + "/" + truckDevice.id, truckDevice).then(getData);
            } else {
                return $http.post(getServiceUrl("truckDevice"), truckDevice).then(getData);
            }
        }

        function deleteTruckDevice(id) {
            return $http.delete(getServiceUrl("truckDevice") + "/" + id).then(getData);
        }

        function getAllSignals(id) {
            var body = {
                params: {
                    monitorableId: id
                }
            };
            return $http.get(getServiceUrl("monitorableSignals"), body).then(getData);
        }

        function getSignalsByMonitorableAndDevice(id, device){
            var body = {
                params: {
                    monitorableId: id,
                    deviceId: device,
                }
            };
            return $http.get(getServiceUrl("monitorableDeviceSignals"), body).then(getData);
        }

        function getSignalsByMonitorableAndDevicePageable(id, device, firstResult, maxResult){
            return $http({
                url: getServiceUrl("monitorableDeviceSignalsPageable"),
                method: "GET",
                params: {
                    monitorableId: id,
                    deviceId: device,
                    firstResult: firstResult,
                    maxResult: maxResult
                }
            }).then(getData);
        }

        function getLatestOrphanSignals(){
            return $http.get(getServiceUrl("latestOrphanSignals")).then(getData);
        }

        function getSignalToleranceInterval(){
            return $http.get(getServiceUrl("toleranceInterval")).then(getData);
        }
    }]);
});