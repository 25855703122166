define([
    "angular",
    "./monitorableModule",
], function (angular, module) {
    "use strict";

    return module.service("monitorableService", [
        "monitoringService",
        "messagesModal",
        "loadingService",
        "mbrService",
        "remoteExceptionHandler",
        "remoteMessageModal",
        "trackMonitorableModal",
        "DataFetcherType",
        "$q",
        function (monitoringService, messagesModal, loadingService, mbrService, remoteExceptionHandler, remoteMessageModal, trackMonitorableModal, DataFetcherType, $q) {
            var self = this;

            self.findMonitorableStatus = function (monitorableId) {
                return monitoringService.findMonitorableStatus(monitorableId).catch(remoteExceptionHandler());
            };

            self.findRootMonitorablePresentationTreeById = function (monitorableId) {
                return monitoringService.findRootMonitorablePresentationTreeById(monitorableId).catch(remoteExceptionHandler());
            };

            self.updateTrackingInfo = function (ids) {
                var monitorables = [];
                var deviceByMonitorablesCreate = [];
                var deviceByMonitorablesStart = [];
                var startMonitorable = false;
                var promises = [];
                return loadingService(monitoringService.findMonitorableByIds(ids, [DataFetcherType.DRIVER])
                    .catch(remoteExceptionHandler()))
                    .then(function (_monitorables) {
                        return monitorables = _monitorables
                    }).then(function () {
                        var messages = isMonitorablesInExecution(monitorables);
                        if (messages.length > 0) {
                            messagesModal("dialog.warning", messages);
                            return $q.reject("Monitorables in execution");
                        }
                        return trackMonitorableModal(monitorables)
                    })
                    .then(function (response) {
                        deviceByMonitorablesCreate = response.devicesByMonitorableCreate;
                        deviceByMonitorablesStart = response.devicesByMonitorableStart;
                        startMonitorable = response.startMonitorable;
                    }).then(function () {
                        if (!startMonitorable) {
                            return confirmOperation("monitoring.monitorable.header.save.without.start.confirmation", monitorables);
                        }
                        return confirmOperation("monitoring.monitorable.header.start.confirmation", monitorables);
                    })
                    .then(function () {
                        promises.push(loadingService(mbrService.updateTrackingInfo(deviceByMonitorablesCreate)).catch(remoteExceptionHandler()));
                        promises.push(loadingService(mbrService.updateTrackingInfoAndStart(deviceByMonitorablesStart)).catch(remoteExceptionHandler()));
                        return $q.all(promises);
                    }).then(function (remoteMessages) {
                        return remoteMessageModal(remoteMessages[0].concat(remoteMessages[1]));
                    });
            };

            self.finishMonitorables = function (ids) {
                var monitorables = [];
                return changeMonitorableStatus(
                    "monitoring.monitorable.header.finish.confirmation",
                    ids,
                    mbrService.finishMonitorables);
            };

            self.cancelMonitorables = function (ids) {
                return changeMonitorableStatus(
                    "monitoring.monitorable.header.cancel.confirmation",
                    ids,
                    mbrService.cancelMonitorables);
            };

            function changeMonitorableStatus(warningMessage, monitorableIds, alterStatusById) {
                var monitorables = [];
                return loadingService(monitoringService.findMonitorableByIds(monitorableIds)
                    .catch(remoteExceptionHandler()))
                    .then(function (_monitorables) {
                        return monitorables = _monitorables;
                    })
                    .then(function () {
                        return confirmOperation(warningMessage, monitorables);
                    }).then(function () {
                        return loadingService(alterStatusById(monitorables.map(function (monitorable) {
                            return monitorable.id;
                        }))).catch(remoteExceptionHandler());
                    }).then(function (remoteMessages) {
                        return remoteMessageModal(remoteMessages);
                    });
            }

            function isMonitorablesInExecution(monitorables) {
                const IN_EXECUTION = "br.com.neolog.monitoring.monitorable.model.api.CompletionStatus.IN_EXECUTION";
                var messages = [];
                angular.forEach(monitorables, function (monitorable) {
                    if (monitorable.completion.status === IN_EXECUTION) {
                        messages.push({
                            keyBundle: "monitoring.monitorable.execution.modal.violation",
                            parameters: monitorable
                        });
                    }
                });
                return messages;
            }

            function confirmOperation(warningMessage, monitorables) {
                return messagesModal.cancellable("dialog.warning", [{
                    keyBundle: warningMessage,
                    parameters: monitorables.map(function (monitorable) {
                        return monitorable.sourceId;
                    })
                }]);
            }

            return self;
        }]);
});