define([
    "./fetcherModule"
], function (module) {
    "use strict";

    return module.service("dataFetchers", ["DataFetcherType", function (DataFetcherType) {
        return {
            ALL: [
                DataFetcherType.TRANSITION,
                DataFetcherType.DRIVER,
                DataFetcherType.OCCURRENCE,
                DataFetcherType.EXTENSION,
                DataFetcherType.COMMENTS,
                DataFetcherType.CHILDREN,
                DataFetcherType.INVOICE_ITEMS,
                DataFetcherType.ROOT,
                DataFetcherType.TRACKING_DETAILS
            ]
        };
    }]);
});