define([
    "./monitorableModule",
    "text!./trackMonitorableModal.html",
    "front-end-web-commons/app/arrays/arrays",
], function (module, template, arrays) {
    "use strict";

    return module.service("trackMonitorableModal", [
        "$modal",
        "monitoringService",
        "trackingService",
        "CompletionStatus",
        "sensorConfigurationByDeviceModal",
        "monitoringVelocityConfigurationModal",
        "$q",
        "secondaryDevicesModal",
        function ($modal, monitoringService, trackingService, CompletionStatus, sensorConfigurationByDeviceModal, monitoringVelocityConfigurationModal, $q, secondaryDevicesModal) {
            return function (monitorables) {
                return $modal.open({
                    size: "lg",
                    template: template,
                    controller: ["$scope", function ($scope) {
                        const FINISHED = "br.com.neolog.monitoring.monitorable.model.api.CompletionStatus.FINISHED";
                        const CANCELLED = "br.com.neolog.monitoring.monitorable.model.api.CompletionStatus.CANCELLED";
                        $scope.shouldStart = false;
                        $scope.onlyFreeDrivers = false;

                        let devicePromises = monitorables.map(function (monitorable) {
                            return trackingService.findByMonitorableId(monitorable.id).then(function (trackingMonitorables) {
                                monitorable.primaryTruckDevice = {};
                                monitorable.secondaryDevices = [];
                                return $q.all(trackingMonitorables.map(function (trackingMonitorable) {
                                    var truckDeviceId = trackingMonitorable.truckDeviceId;
                                    return trackingService.findDevice(truckDeviceId).then(function (truckDevice) {
                                        if (trackingMonitorable.primary) {
                                            monitorable.truckDevice = truckDevice;
                                        } else {
                                            monitorable.secondaryDevices.push(truckDevice);
                                        }
                                    });
                                }));
                            });
                        });


                        $scope.drivers = function (viewValue) {
                            if ($scope.onlyFreeDrivers) {
                                return monitoringService.findFreeDrivers(viewValue)
                            }
                            return monitoringService.findDrivers(viewValue);
                        };

                        $scope.filterByDescription = function (viewValue, deviceByMonitorable) {
                            if ($scope.onlyFreeDrivers) {
                                return trackingService.findByDescriptionLikeWithoutMonitorable(viewValue).then(function (truckDevices) {
                                    return arrays.minus(truckDevices, deviceByMonitorable.secondaryDevices);
                                });
                            }
                            return trackingService.findByDescriptionLike(viewValue).then(function (truckDevices) {
                                return arrays.minus(truckDevices, deviceByMonitorable.secondaryDevices);
                            });
                        };

                        $scope.filterByDeviceId = function (viewValue, deviceByMonitorable) {
                            if($scope.onlyFreeDrivers){
                                return trackingService.findByDeviceIdLikeWithoutMonitorable(viewValue).then(function (truckDevices) {
                                    return arrays.minus(truckDevices, deviceByMonitorable.secondaryDevices);
                                });
                            }
                            return trackingService.findByDeviceIdLike(viewValue).then(function (truckDevices) {
                                return arrays.minus(truckDevices, deviceByMonitorable.secondaryDevices);
                            });
                        };

                        $scope.formatTag = function (tag) {
                            if (tag) {
                                return tag.deviceId;
                            }
                        };

                        function DeviceByMonitorable(monitorable) {
                            this.monitorable = monitorable;
                            this.driver = monitorable.currentDriver;
                            this.truckDevice = monitorable.truckDevice;
                            this.secondaryDevices = monitorable.secondaryDevices;
                        }

                        DeviceByMonitorable.prototype = {
                            hasStatusFinishedOrCancelled() {
                                let status = this.monitorable.completion.status;
                                return status === FINISHED || status === CANCELLED;
                            },
                            areDriverAndDeviceSet() {
                                return !!(this.driver);
                            },
                            arePrimaryTruckDeviceSet() {
                                return !!(this.truckDevice);
                            }
                        };

                        $scope.deviceByMonitorables = [];
                        $q.all(devicePromises).then(function () {
                            monitorables.forEach(function (monitorable) {
                                $scope.deviceByMonitorables.push(new DeviceByMonitorable(monitorable));
                            });
                        });

                        $q.all(devicePromises).then(function () {
                            monitorables.forEach(function (monitorable) {
                                $scope.monitorableSourceId = monitorable.sourceId;
                            });
                        });

                        function createTrackingMonitorableDto(devicesByMonitorable, truckDevice, primary) {
                            return {
                                monitorableId: devicesByMonitorable.monitorable.id,
                                driverId: devicesByMonitorable.driver && devicesByMonitorable.driver.id,
                                truckDeviceId: truckDevice.id,
                                primary: primary
                            };
                        }

                        $scope.assign = function () {
                            let devicesByMonitorable = $scope.deviceByMonitorables.flatMap(function (devicesByMonitorable) {
                                var trackingMonitorableDtos = [];
                                trackingMonitorableDtos.push(createTrackingMonitorableDto(devicesByMonitorable, devicesByMonitorable.truckDevice, true));
                                devicesByMonitorable.secondaryDevices.map(function (truckDevice) {
                                    trackingMonitorableDtos.push(createTrackingMonitorableDto(devicesByMonitorable, truckDevice, false));
                                });
                                return trackingMonitorableDtos;
                            });

                            let devicesByMonitorableStart = $scope.deviceByMonitorables.filter(function (deviceByMonitorable) {
                                return deviceByMonitorable.monitorable.completion.status === CompletionStatus.IN_EXECUTION;
                            }).flatMap(function (devicesByMonitorable) {
                                var trackingMonitorableStartDtos = [];
                                trackingMonitorableStartDtos.push(createTrackingMonitorableDto(devicesByMonitorable, devicesByMonitorable.truckDevice, true));
                                devicesByMonitorable.secondaryDevices.map(function (truckDevice) {
                                    trackingMonitorableStartDtos.push(createTrackingMonitorableDto(devicesByMonitorable, truckDevice, false));
                                });
                                return trackingMonitorableStartDtos;
                            });

                            let devicesByMonitorableCreate = $scope.deviceByMonitorables.filter(function (deviceByMonitorable) {
                                return deviceByMonitorable.monitorable.completion.status === CompletionStatus.CREATED;
                            }).flatMap(function (devicesByMonitorable) {
                                var trackingMonitorableCreateDtos = [];
                                trackingMonitorableCreateDtos.push(createTrackingMonitorableDto(devicesByMonitorable, devicesByMonitorable.truckDevice, true));
                                devicesByMonitorable.secondaryDevices.map(function (truckDevice) {
                                    trackingMonitorableCreateDtos.push(createTrackingMonitorableDto(devicesByMonitorable, truckDevice, false));
                                });
                                return trackingMonitorableCreateDtos;
                            });

                            let response = {
                                devicesByMonitorableCreate: $scope.shouldStart ? [] : devicesByMonitorableCreate,
                                devicesByMonitorableStart: $scope.shouldStart ? devicesByMonitorable : devicesByMonitorableStart,
                                startMonitorable: $scope.shouldStart
                            };

                            $scope.$close(response);
                        };

                        $scope.openSecondaryDevicesModalConfiguration = function (deviceByMonitorable) {
                            return secondaryDevicesModal(deviceByMonitorable);
                        };

                        $scope.openSensorModalConfiguration = function (deviceByMonitorable) {
                            if (!$scope.existTuckDevice(deviceByMonitorable)){
                                return;
                            }
                            return sensorConfigurationByDeviceModal(deviceByMonitorable);
                        };

                        $scope.existTuckDevice = function (deviceByMonitorable) {
                            return !!deviceByMonitorable.truckDevice;
                        }

                        $scope.openMonitoringVelelocityConfigurationModal = function (deviceByMonitorable) {
                            return monitoringVelocityConfigurationModal(deviceByMonitorable);
                        };

                        $scope.isValid = function () {
                            if (!!$scope.deviceByMonitorables) {
                                let deviceByMonitorablesCreatedOrInExecution = $scope.deviceByMonitorables.filter(function (monitoring) {
                                    return !monitoring.hasStatusFinishedOrCancelled();
                                });

                                return deviceByMonitorablesCreatedOrInExecution.every(function (deviceByMonitorable) {
                                    if (!$scope.shouldStart) {
                                        return deviceByMonitorable.areDriverAndDeviceSet();
                                    }
                                    return deviceByMonitorable.areDriverAndDeviceSet() && deviceByMonitorable.arePrimaryTruckDeviceSet();
                                });
                            }
                            return false;
                        };
                    }
                    ]
                }).result;
            };
        }]);
});
