define([
    "./monitorableModule",
    "json!../tdnByEntity.json",
], function (monitorableModule, tdnByEntity) {
    "use strict";

    const NodeTypes = {
        TRIP: "Trip",
        LOAD: "Load",
        INVOICE: "Invoice",
        DOCUMENT: "Document",
        TRANSITION: "Transition",
    };

    return monitorableModule.controller("MonitorableSearchController", [
        "$scope",
        "monitoringService",
        "monitorableService",
        "transitionService",
        "monitoringBasketService",
        "transitionSpreadsheetService",
        "transitionFailureReasonModal",
        "mbrService",
        "tdnHelp",
        function ($scope, monitoringService, monitorableService, transitionService, monitoringBasketService, transitionSpreadsheetService, transitionFailureReasonModal, mbrService, tdnHelp) {
            $scope.tdn = tdnByEntity?.[tdnHelp.getContext()]?.["monitorable-search"];
            const defaultView = {
                keyBundle: "monitoring.viewTypes.destinationView",
                monitoringBasketServiceUrl: "monitorable/by-destination",
            };

            $scope.viewType = {
                currentView: defaultView,
                possibleViews: {
                    "MONITORABLE_VIEW": {
                        keyBundle: "monitoring.viewTypes.monitorableView",
                        monitoringBasketServiceUrl: "monitorable",
                    },
                    "DESTINATION_VIEW": defaultView,
                    "TEMPERATURE_VIEW": {
                        keyBundle: "monitoring.viewTypes.temperatureView",
                        monitoringBasketServiceUrl: "monitorable/by-temperature",
                    }
                }
            };

            $scope.basket = {
                monitoringBasketService: monitoringBasketService,
                selection: {},
                api: null
            };

            $scope.onRegisterBasketApi = function (api) {
                $scope.basket.api = api;
            };

            $scope.hasMonitorableSelection = function () {
                return getSelectedMonitorables().length;
            };

            $scope.finishMonitorables = function () {
                return modifyNodesAndRefresh(getSelectedMonitorables(), monitorableService.finishMonitorables);
            };

            $scope.startMonitorables = function () {
                return modifyNodesAndRefresh(getSelectedMonitorables(), monitorableService.updateTrackingInfo);
            };

            $scope.cancelMonitorables = function () {
                return modifyNodesAndRefresh(getSelectedMonitorables(), monitorableService.cancelMonitorables);
            };

            $scope.hasTransitionSelection = function () {
                return getSelectionType(NodeTypes.TRANSITION).length;
            };

            $scope.finalizeTransitions = function () {
                return modifyNodesAndRefresh(getSelectionType(NodeTypes.TRANSITION), transitionService.accomplishTransitions);
            };

            $scope.notAccomplishTransitions = function () {
                const selections = getSelectionType(NodeTypes.TRANSITION).map((node) => node.id);
                const invoiceNodes = getSelectionType(NodeTypes.INVOICE);
                const selectedTransitionNodes = $scope.basket.selection[NodeTypes.TRANSITION];
                transitionFailureReasonModal(selections, null, null, invoiceNodes, selectedTransitionNodes).then(() => $scope.basket.api.refreshFilter());
            };

            $scope.openImportModal = function () {
                return transitionSpreadsheetService.openImportModal().then($scope.reload);
            };

            $scope.downloadAttachments = function () {
                var selectionTripId = getSelectionType(NodeTypes.TRIP).map((node) => node.id);
                if (selectionTripId.length > 0) {
                    mbrService.downloadAllMonitorablesAttachments(getSelectionType(NodeTypes.TRIP).map((node) => node.id));
                }
            }

            $scope.hasTripSelection = function () {
                return getSelectionType(NodeTypes.TRIP).length;
            };

            function getSelectedMonitorables() {
                return getSelectionType(NodeTypes.TRIP)
                    .concat(getSelectionType(NodeTypes.LOAD))
                    .concat(getSelectionType(NodeTypes.INVOICE))
                    .concat(getSelectionType(NodeTypes.DOCUMENT));
            }

            function getSelectionType(type) {
                return $scope.basket.selection[type] || [];
            }

            function modifyNodesAndRefresh(nodes, modifier) {
                return modifier(nodes.map(node => node.id)).then(() => $scope.basket.api.refreshFilter());
            }

        }]);
});
