define(["../transitionFailureReasonModule", "lodash"],
    function (module, _) {
        "use strict";

        return module.controller("TransitionFailureReasonControllerEdition", [
            "$scope",
            "$state",
            "transitionFailureReason",
            "monitoringService",
            "transitionFailureReasonService",
            function ($scope, $state, transitionFailureReason, monitoringService, transitionFailureReasonService) {
                $scope.entity = transitionFailureReason;
                $scope.isCurrentDefault = transitionFailureReason.defaultReason;

                $scope.save = function () {
                    return transitionFailureReasonService.save($scope.entity)
                        .then(function (id) {
                            return $state.go("monitoring.transitionFailureReasonEdition", {
                                transitionFailureReasonId: id
                            });
                        });
                };

                $scope.saveAndContinue = function () {
                    return transitionFailureReasonService.save($scope.entity)
                        .then(function () {
                            return $state.go($state.current, {reload: Date.now()});
                        });
                };

                $scope.back = function () {
                    return $state.go("monitoring.transitionFailureReasonSearch", {
                        reload: true
                    });
                };
            }
        ]);
    });