define([
    "./transitionFailureReasonModule",
    "text!./transitionFailureReasonModal.html",
], function (module, template) {
    "use strict";

    return module.service("transitionFailureReasonModal", [
        "$modal",
        "monitoringService",
        "messagesModal",
        "transitionService",
        function ($modal, monitoringService, messagesModal, transitionService) {
            return function (transitionIds, timestamp, monitorable, invoiceNodes, transitionNodes ) {
                if(monitorable) {
                    if (monitorable.typeName === 'INVOICE') {//visão por hierarquia: clicou em engrenagem > transições > Realizado (falha), e era invoice
                        return singleInvoiceFailureModal(transitionIds, timestamp, monitorable, null);
                    } else { //visão por hierarquia: clicou em engrenagem > transições > Realizado (falha), mas não era invoice
                        return skipFailureReasonTransitionModal(transitionIds, timestamp);
                    }
                }else if(invoiceNodes && invoiceNodes.length > 0){//visão por hierarquia: selecionou transitions com ao menos uma invoice > droppdown Transitions > Finalizar (falha)
                    return massTransitionFailureWithAtLeastOneInvoiceModal(transitionIds, timestamp, invoiceNodes);
                }else if(transitionNodes && transitionNodes.length > 0){//visão por endereço, droppdown Transitions ou engrenagem -> Finalizar (falha)
                    const invoiceTransitionNodes = getInvoiceTransitionNodes(transitionNodes);
                    if(invoiceTransitionNodes.length > 0) {
                        if (transitionNodes.length == 1 && invoiceTransitionNodes.length == 1) {//visão por endereço: clicou na engrenagem da transition que é de invoice, ou só selecionou a transition de invoice e clicou no dropdown Transitions
                            return singleInvoiceFailureModal(transitionIds, timestamp, null, invoiceTransitionNodes[0]);
                        } else {//visão por endereço: selecionou mais de uma transition, com ao menos uma transition de invoice
                            return massTransitionFailureWithAtLeastOneInvoiceModal(transitionIds, timestamp, invoiceTransitionNodes);
                        }
                    }
                    const invoiceNodesInParents = getInvoiceNodesInParents(transitionNodes);
                    if(invoiceNodesInParents.length > 0){//visão hierárquica: selecionou a transição filha do monitorável invoice  e clicou no dropdown Transitions ou foi na engrenagem dessa transição
                        if(invoiceNodesInParents.length === 1){
                            singleInvoiceFailureModal(transitionIds, timestamp, null, (invoiceNodesInParents[0]));
                        }else{
                            return massTransitionFailureWithAtLeastOneInvoiceModal(transitionIds, timestamp, invoiceTransitionNodes);
                        }
                    }
                    else{//visão por endereço ou hierarquia: selecionou uma ou mais transitions mas sem nenhuma invoice, ou engrenagem na transition que não era invoice
                        return skipFailureReasonTransitionModal(transitionIds, null);
                    }
                }
            }

            function getMonitorableCodeFromInvoiceTransitionNode(invoiceTransitionNode){
                return invoiceTransitionNode.headerProperties.find((property) =>{
                    return property.messageKey == 'monitorable.code';
                }).value;
            }

            function extractVolumeAndWeightFromProperties(properties){

                let volumeAndWeight = {};
                for(let i = 0; i < properties.length; i++){
                    if(properties[i].messageKey === "monitorable.volume"){
                        volumeAndWeight.volume = properties[i].value;
                    }
                    if(properties[i].messageKey === "monitorable.weight"){
                        volumeAndWeight.weight = properties[i].value;
                    }
                }
                return volumeAndWeight;
            }

            function getInvoiceNodesInParents(transitionNodes){
                let invoiceNodesInParents = [];
                for(let i = 0; i < transitionNodes.length; i++){
                    if(transitionNodes[i].$parent.type.name === 'Invoice'){
                        invoiceNodesInParents.push(transitionNodes[i].$parent);
                    }
                }
                return invoiceNodesInParents;
            }

            function getInvoiceTransitionNodes(nodes){
                const invoiceTransitionNodes = [];
                for(let i = 0; i < nodes.length; i++){
                    const properties = nodes[i].headerProperties;
                    for(let j = 0; j < properties.length; j++){
                        if(properties[j].messageKey === "monitorable.type"){
                            if(properties[j].value === "br.com.neolog.monitoring.monitorable.model.api.StandardMonitorableType.INVOICE"){
                                invoiceTransitionNodes.push(nodes[i]);
                            }else{
                                break;
                            }
                        }
                    }
                }
                return invoiceTransitionNodes;
            }

            function singleInvoiceFailureModal(transitionIds, timestamp, monitorableInvoice, invoiceTransitionNode){
                return $modal.open({
                    size: "lg",
                    template: template,
                    controller: ["$scope", $scope => {
                        $scope.showInputForNotDeliveredQuantities =  true;
                        $scope.newComment = {};
                        $scope.notDeliveredVolume = {value: ''};
                        $scope.notDeliveredWeight = {value: ''};
                        monitoringService.findTransitionFailureReasonsDefault().then((data) => {
                            $scope.failureReason = data;
                        });

                        $scope.availableTransitionFailureReasons = () => {
                            return monitoringService.findAllTransitionFailureReasons();
                        };
                        
                        if(monitorableInvoice) {//visão hierárquica, engrenagem da invoice > transições
                            $scope.monitorableInvoice = monitorableInvoice;
                        }else if(invoiceTransitionNode.type.name === "Invoice"){ //visão hierárquica, mas, ao invés de clicar na engrenagem da própria invoice, clicou na engrenagem da transição filha da invoice
                                $scope.monitorableInvoice = extractVolumeAndWeightFromProperties(invoiceTransitionNode.properties);
                        }else{ //visão por endereço, clicou na transition da invoice, mas não temos como acessar a invoice sem uma requisição
                                const monitorableSourceId = getMonitorableCodeFromInvoiceTransitionNode(invoiceTransitionNode);
                                monitoringService.findMonitorableBySourceId(monitorableSourceId).then(
                                    monitorableInvoiceFromRequest => {
                                        $scope.monitorableInvoice = monitorableInvoiceFromRequest;
                                    });
                        }
                        $scope.save = () => {
                            const notDeliveredQuantities = transformIfEmptyInput($scope.notDeliveredVolume.value, $scope.notDeliveredWeight.value,  $scope.monitorableInvoice);
                            const modifyTransitions = saveTransitions(transitionIds, timestamp, $scope.newComment,
                                $scope.failureReason.id, notDeliveredQuantities['volume'], notDeliveredQuantities['weight']);
                            modifyTransitions.then(() => {
                                return $scope.$close();
                            });
                        };
                    }]
                }).result;
            }
            function transformIfEmptyInput(notDeliveredVolume, notDeliveredWeight, invoiceVolumeAndWeight){
                if((notDeliveredVolume == '' && notDeliveredWeight == '') ||
                    (notDeliveredVolume == 0 && notDeliveredWeight == 0)){
                    return {volume: invoiceVolumeAndWeight.volume, weight: invoiceVolumeAndWeight.weight};
                }
                if(notDeliveredVolume == ''){
                    return {volume: null, weight: notDeliveredWeight};
                }
                if(notDeliveredWeight == ''){
                    return {volume: notDeliveredVolume, weight: null};
                }
                return {volume: notDeliveredVolume, weight: notDeliveredWeight};
            }
            function saveTransitions(transitionIds, timestamp, comment, failureReasonId, notDeliveredVolume, notDeliveredWeight){
                let modifyTransitions;
                if (transitionIds.length === 1) {
                    modifyTransitions = transitionService.notAccomplishTransition(transitionIds[0],
                        timestamp, comment, failureReasonId, notDeliveredVolume, notDeliveredWeight);
                } else {
                    modifyTransitions = transitionService.notAccomplishTransitions(transitionIds,
                        comment, failureReasonId, notDeliveredVolume, notDeliveredWeight);
                }
                return modifyTransitions;
            }

            function massTransitionFailureWithAtLeastOneInvoiceModal(transitionIds, timestamp, monitorableInvoiceNodes){
                //monitorableInvoiceNodes mantido para uma eventual
                return $modal.open({
                    size: "lg",
                    template: template,
                    controller: ["$scope", $scope => {
                        $scope.showInputForNotDeliveredQuantities =  false;
                        $scope.newComment = {};
                        monitoringService.findTransitionFailureReasonsDefault().then((data) => {
                            $scope.failureReason = data;
                        });

                        $scope.availableTransitionFailureReasons = () => {
                            return monitoringService.findAllTransitionFailureReasons();
                        };

                        $scope.save = () => {
                            const modifyTransitions = saveTransitions(transitionIds, timestamp, $scope.newComment,
                                $scope.failureReason.id, null, null);
                            modifyTransitions.then(() => {
                                return $scope.$close();
                            });
                        };
                    }]
                }).result;
            }

            function skipFailureReasonTransitionModal(transitionIds, timestamp){
                return saveTransitions(transitionIds, timestamp, null, null, null,
                    null);
            }

        }]);
});